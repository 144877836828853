import PropTypes from 'prop-types'
import Seo from '@components/Seo'
import DataViewer from '@utils/DataViewer'
import PageHeader from '@components/PageHeader'
import FeedbackForm from '@components/FeedbackForm'
import RenderModules from '@components/RenderModules'
import Intro from '@components/Intro'
import HomepageHeader from '@components/HomepageHeader'

const Page = ({ data }) => {

	const page = data.page

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			{page?.slug === 'home' 
				? <HomepageHeader content={page?.header} fullImage/>
				: <PageHeader content={page?.header} page={page}/>
			}
				{page?.introduction && <Intro content={page}  />}
				<RenderModules modules={page?.modules} />
				{page?.showFeedbackForm &&
					<FeedbackForm />
				}
			<DataViewer data={data} name="data" />
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}

export default Page